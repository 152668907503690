import React from "react";
import styled from "styled-components";

import { lazyInlineSvg } from "../../static/LazyInlineSvg.js";
import { Heading } from "../Heading.js";

import calculationsImage from "./images/calculations.png";
import calculationsImage2x from "./images/calculations@2x.png";
import dataBrowserImage from "./images/data-browser.png";
import dataBrowserImage2x from "./images/data-browser@2x.png";
import endorsedStatusesImage from "./images/endorsed-statuses.png";
import endorsedStatusesImage2x from "./images/endorsed-statuses@2x.png";

const HeaderIllustration = lazyInlineSvg(
  () => import("./images/spring-launch-modal-hero.inline.svg"),
);

/* eslint-disable tree-shaking/no-side-effects-in-initialization */
const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledHeading = styled(Heading)`
  color: white;
  font-size: 28px;
  line-height: 1.5;
  margin: auto;
`;

const Desc = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  padding: 0 20px;
`;

export type ProductLaunchConfig = {
  blogPostUrl?: string;
  enabled: boolean;
  headerIllustration: React.ReactNode;
  id: string;
  tabs: Array<{
    description: React.ReactNode;
    imageUrl?: string;
    imageUrl2x?: string;
    title: string;
    videoUrl?: string;
  }>;
};

// Do not update this, launches are controlled by the same feature flag every time
export const PRODUCT_LAUNCH_FEATURE_FLAG = "product-launch";

/**
 * =======================update everything below here for a new launch===================
 *
 * Notes for future travellers:
 * - images are checked into the codebase and videos are uploaded to our static site
 * - use video URLs of the format "https://static.hex.site/2024-launch-autoplay.mp4" to ensure compatability across all stacks (CSP issues)
 *
 * =======================================================================================
 */

// Update this for every launch {season}-launch-{year}. This will be used to carry over to user local storage for visibility
export const PRODUCT_LAUNCH_ID = "spring-launch-2024";

// Update launch modal contents here
export const PRODUCT_LAUNCH_CONFIG: ProductLaunchConfig = {
  id: PRODUCT_LAUNCH_ID,
  enabled: false, // override disable in the code once launch is done
  headerIllustration: <HeaderIllustration />,
  blogPostUrl: "https://hex.tech/blog/spring-release-2024",
  tabs: [
    {
      title: "Overview",
      description: (
        <HeadingWrapper>
          <StyledHeading renderAs="h1">
            (Internal testing only) Headline...
          </StyledHeading>
          <Desc>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Desc>
        </HeadingWrapper>
      ),
      videoUrl: "https://static.hex.site/2024-launch-autoplay.mp4",
    },
    {
      title: "Feature 1",
      description: (
        <Desc>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Desc>
      ),
      imageUrl: calculationsImage,
      imageUrl2x: calculationsImage2x,
    },
    {
      title: "Feature 2",
      description: (
        <Desc>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Desc>
      ),
      imageUrl: endorsedStatusesImage,
      imageUrl2x: endorsedStatusesImage2x,
    },
    {
      title: "Feature 3",
      description: (
        <Desc>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Desc>
      ),
      imageUrl: dataBrowserImage,
      imageUrl2x: dataBrowserImage2x,
    },
  ],
};
